<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      md="6"
      class="leftPanel align-center"
      :class="{ 'full-page': $vuetify.breakpoint.mdAndUp }"
    >
      <v-row class="ma-16">
        <v-col cols="2"></v-col>
        <v-col cols="8">
          <v-img
            :src="
              require(`@/assets/core/just_logo_${
                $vuetify.theme.dark ? 'light3' : 'dark3'
              }.png`)
            "
            max-height="1200px"
          />
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <h2 class="textHeaderLeftPanel--text">Welkom bij Grant Thornton</h2>
      </v-row>
      <v-row class="justify-center mt-10">
        <h3 class="textHeaderLeftPanel--text">
          Liever een ander kleurschema klik op de knop
        </h3></v-row
      >
      <v-row class="justify-center mt-10">
        <div class="text-center">
          <v-btn class="secondary mb-10" @click="toggle_dark_mode">
            <v-icon left>mdi-theme-light-dark</v-icon>
            <span>Donker/Licht</span>
          </v-btn>
        </div>
      </v-row>
      <v-row class="mt-10">
        <v-col cols="2" />
        <v-col cols="8">
          <h3 class="textHeaderLeftPanel--text">Wachtwoord</h3>
          <br />
          <h4 class="textLeftPanel--text mb-15">
            U ontvangt een e-mailbericht met een zescijferige code. Deze vult u
            als password in.<br />
            <br />
            Controleer eventueel uw spambox.
          </h4>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="6" class="justify-center align-center">
      <div class="ma-5 pa-0">
        <v-row class="ml-15 mr-15 mt-15">
          <v-col cols="12" class="mt-15 mb-15"></v-col>
          <v-col cols="12" class="mt-15 mb-15"></v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              shaped
              placeholder="password"
              v-model="password"
              required
            />
            <v-row>
              <flash-message :error="error" v-if="error" key="error" />
            </v-row>
            <div class="text-end mt-5 mr-15">
              <v-btn class="accent white--text mr-8" v-if="errorBtn" @click="startOver"
                >nw code</v-btn
              >
              <v-btn
                elevation="15"
                class="accent white--text"
                v-if="errorBtn"
                @click="login"
                >nogmaals</v-btn
              >
              <v-btn
                elevation="15"
                class="accent white--text"
                v-if="!errorBtn"
                @click="login"
                >Verder</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { getError } from "@/utils/helpers";
import { mapGetters } from "vuex";
import FlashMessage from "@/components/FlashMessage";

export default {
  name: "LoginB",
  components: { FlashMessage },
  data() {
    return {
      error: null,
      password: null,
      valid: false,
      errorBtn: false,
    };
  },
  methods: {
    startOver() {
      this.$router.push({ name: "Login" });
    },
    toggle_dark_mode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    async login() {
      let data = {
        email: this.email,
        token: this.password,
      };
      this.error = null;
      // axios.post("/loginchart", data, {
      //   headers: {
      //   },
      // });

      try {
        await this.$store.dispatch("auth/loginB", data);
        if (this.stepTwo) {
          localStorage.setItem("email", this.email);
          this.$router.push({ name: "Modules" });
        } else {
          console.log("error");
          this.errorBtn = true;
          const error = Error(
            "Dit wachtwoord is onjuist of verlopen, probeer het opnieuw of begin opnieuw U krijgt dan een een nieuw wachtwoord toegezonden."
          );
          error.name = "Fetch User";
          throw error;
        }
      } catch (error) {
        this.error = getError(error);
      }
    },
  },
  mounted() {},
  computed: {
    ...mapGetters("auth", ["stepTwo", "email"]),
  },
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal !important; /* maybe !important  */
}

.height-col {
  height: 100vh;
}
</style>
